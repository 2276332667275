<template>
  <div>
    <v-snackbar v-if="color != 'error'" :timeout="timeout" v-model="active" fixed top :color="color" text center>
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-else :timeout="timeout" v-model="active" fixed top color="error" outlined center>
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    timeout: {
      type: Number,
      default: 2500,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    text() {
      return this.$store.getters.text;
    },
    color() {
      return this.$store.getters.color;
    },
  },
  watch: {
    text(val) {
      val ? (this.active = true) : '';
    },
  },
};
</script>

<style scoped>
.v-snack {
  top: 50px;
}
</style>
