import '@/plugins/axios';
import router from '@/router/index';

const api = '/tutor/api/v1/windows/';
const apiS = '/tutor/api/v1/schedule/';

export default {
  state: {
    windowsList: [],
    scheduleList: [],
    availableList: { windows: [] },
  },
  actions: {
    getWindowsList(context) {
      return axios.get(`${api}`).then((res) => {
        context.commit('GET_WINDOWSLIST', res.data);
      });
    },
    setWindowsList: (context, payload) => {
      context.commit('GET_WINDOWSLIST', payload);
    },
    addWindowsList(context, payload) {
      return axios.post(`${api}`, payload).then((res) => {
        context.commit('GET_WINDOWSLIST', res.data);
      });
    },
    updateWindowsList(context, { id, data }) {
      return axios.put(`${api}${id}`, data).then((res) => {
        context.commit('GET_WINDOWSLIST', res.data);
      });
    },
    deleteWindowsList(context, payload) {
      return axios.delete(`${api}${payload}`).then(() => {
        context.dispatch('getWindowsList');
      });
    },
    getScheduleList(context, { dateFrom, dateTo, course, student }) {
      if (
        `${router.app._route.path}?date-from=${dateFrom}&date-to=${dateTo}${
          course == 'all' ? '' : course == null ? '' : `&course=${course}`
        }
          ${student == 'all' ? '' : student == null ? '' : `&student=${student}`}` != router.app._route.fullPath
      ) {
        window.history.pushState(
          {},
          '',
          `${router.app._route.path}?date-from=${dateFrom}&date-to=${dateTo}${
            course == 'all' ? '' : course == null ? '' : `&course=${course}`
          }${student == 'all' ? '' : student == null ? '' : `&student=${student}`}`
        );
      }
      return axios
        .get(
          `${apiS}?date_from=${dateFrom}&date_to=${dateTo}${course == 'all' ? '' : course == null ? '' : `&course=${course}`}${
            student == 'all' ? '' : student == null ? '' : `&student=${student}`
          }`
        )
        .then((res) => {
          context.commit('GET_SCHEDULELIST', res.data);
        });
    },
    setScheduleList: (context, payload) => {
      context.commit('GET_SCHEDULELIST', payload);
    },
    getAvailableWindowsList(context, payload) {
      return axios.get(`/tutor/api/v1/available-windows/?date=${payload}`).then((res) => {
        context.commit('GET_AVAILABLELIST', res.data);
      });
    },
    setAvailableWindowsList: (context, payload) => {
      context.commit('GET_AVAILABLELIST', payload);
    },
  },
  mutations: {
    GET_WINDOWSLIST: (state, payload) => {
      state.windowsList = payload;
      return state.windowsList;
    },
    GET_SCHEDULELIST: (state, payload) => {
      state.scheduleList = payload;
      return state.scheduleList;
    },
    GET_AVAILABLELIST: (state, payload) => {
      state.availableList = payload;
      return state.availableList;
    },
  },
  getters: {
    windowsList(state) {
      return state.windowsList;
    },
    scheduleList(state) {
      return state.scheduleList;
    },
    availableList(state) {
      return state.availableList;
    },
  },
};
