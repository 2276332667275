import '@/plugins/axios';
import router from '@/router/index';

const api = '/tutor/api/v1/conversations/';

export default {
  state: {
    msgList: { results: [] },
    msgItem: {},
    msgAtt: [],
    msgItemMsg: [],
  },
  actions: {
    getMsgList(context, { search, page }) {
      return axios.get(`${api}?${search ? `src=${search}&` : ''}page=${page - 1 || 0}`).then((res) => {
        context.commit('GET_MSGLIST', res.data);
      });
    },
    setMsgList: (context, payload) => {
      context.commit('GET_MSGLIST', payload);
    },
    newMsg(context, payload) {
      return axios.post(`${api}`, payload).then((res) => {
        router.push(`/messages?dialog=${res.data.id}`);
      });
    },
    getMsgItem(context, payload) {
      return axios.get(`${api}${payload}`).then((res) => {
        context.commit('GET_MSGITEM', res.data);
      });
    },
    updateMsgItem(context, { id, data }) {
      return axios.patch(`${api}${id}`, data).then((res) => {
        context.commit('GET_MSGITEM', res.data);
      });
    },
    setMsgItem: (context, payload) => {
      context.commit('GET_MSGITEM', payload);
    },
    deleteMsgItem(context, payload) {
      return axios.delete(`${api}${payload}`);
    },
    clearMsgHistory(context, payload) {
      return axios.post(`${api}${payload}/clear`);
    },
    blockMsgHistory(context, payload) {
      return axios.post(`${api}${payload}/block`).then((res) => {
        context.commit('GET_MSGITEM', res.data);
      });
    },
    unlockMsgHistory(context, payload) {
      return axios.post(`${api}${payload}/unblock`).then((res) => {
        context.commit('GET_MSGITEM', res.data);
      });
    },
    getMsgAtt(context, payload) {
      return axios.get(`${api}${payload}/attachments/`).then((res) => {
        context.commit('GET_MSGATT', res.data);
      });
    },
    setMsgAtt: (context, payload) => {
      context.commit('GET_MSGATT', payload);
    },
    getMsgItemMsg(context, payload) {
      return axios.get(`${api}${payload}/messages/`).then((res) => {
        context.commit('GET_MSGITEMMSG', res.data.reverse());
        context.dispatch('sendWs', res.data[0]);
      });
    },
    setMsgItemMsg: (context, payload) => {
      context.commit('GET_MSGITEMMSG', payload);
    },
    getMsgItemMsgText(context, { id, text }) {
      return axios.post(`${api}${id}/messages/text`, text);
    },
    getMsgItemMsgAtt(context, { id, data }) {
      return axios({
        method: 'post',
        url: `${api}${id}/messages/attachment`,
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    deleteMsgItemMsg(context, { id, idMSg }) {
      return axios.delete(`${api}${id}/messages/${idMSg}`);
    },
  },
  mutations: {
    GET_MSGLIST: (state, payload) => {
      state.msgList = payload;
      return state.msgList;
    },
    GET_MSGITEM: (state, payload) => {
      state.msgItem = payload;
      return state.msgItem;
    },
    GET_MSGATT: (state, payload) => {
      state.msgAtt = payload;
      return state.msgAtt;
    },
    GET_MSGITEMMSG: (state, payload) => {
      state.msgItemMsg = payload;
      return state.msgItemMsg;
    },
  },
  getters: {
    msgList(state) {
      return state.msgList;
    },
    msgItem(state) {
      return state.msgItem;
    },
    msgAtt(state) {
      return state.msgAtt;
    },
    msgItemMsg(state) {
      return state.msgItemMsg;
    },
  },
};
