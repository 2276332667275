import '@/plugins/axios';
import router from '@/router/index';

const api = '/tutor/api/v1/courses/';

export default {
  state: {
    courseList: [{ id: 'all', title: 'All' }],
    coursesList: { results: [] },
    coursesItem: { options: [], lessons: [], attachments: [] },
  },
  actions: {
    getCourseList: (context) => {
      return axios.get(`${api}all/`).then((res) => {
        context.commit('GET_COURSELIST', res.data);
      });
    },
    setCourseList: (context, payload) => {
      context.commit('GET_COURSELIST', payload);
    },
    getCoursesList(context, { page, status, category }) {
      if (status != 'all' || category != '0') {
        if (
          `${router.app._route.path}${category != 0 ? `?category=${category}` : '?'}${
            status != 'all' ? `&status=${status}` : ''
          }&page=${page}` != router.app._route.fullPath
        ) {
          window.history.pushState(
            {},
            '',
            `${router.app._route.path}${category != 0 ? `?category=${category}` : '?'}${
              status != 'all' ? `&status=${status}` : ''
            }&page=${page}`
          );
        }
        return axios
          .get(`${api}${category != 0 ? `?category=${category}` : '?'}${status != 'all' ? `&status=${status}` : ''}&page=${page - 1 || 0}`)
          .then((res) => {
            context.commit('GET_COURSESLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          window.history.pushState({}, '', `${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_COURSESLIST', res.data);
        });
      }
    },
    setCoursesList: (context, payload) => {
      context.commit('GET_COURSESLIST', payload);
    },
    addCourses(context, payload) {
      return axios.post(`${api}`, payload);
    },
    getCoursesItem(context) {
      return axios.get(`${api}${router.app._route.params.coursesId}`).then((res) => {
        context.commit('GET_COURSESITEM', res.data);
      });
    },
    setCoursesItem: (context, payload) => {
      context.commit('GET_COURSESITEM', payload);
    },
    updateCoursesItem(context, payload) {
      return axios.patch(`${api}${router.app._route.params.coursesId}`, payload).then((res) => {
        context.commit('GET_COURSESITEM', res.data);
      });
    },
    deleteCoursesItem() {
      return axios.delete(`${api}${router.app._route.params.coursesId}`).then(() => {
        router.push('/');
      });
    },
    activateCoursesItem(context) {
      return axios.post(`${api}${router.app._route.params.coursesId}/activate`).then((res) => {
        context.commit('GET_COURSESITEM', res.data);
      });
    },
    deactivateCoursesItem(context) {
      return axios.post(`${api}${router.app._route.params.coursesId}/deactivate`).then((res) => {
        context.commit('GET_COURSESITEM', res.data);
      });
    },
    addAttachmentsCoursesItem(context, payload) {
      return axios({
        method: 'post',
        url: `${api}${router.app._route.params.coursesId}/attachments/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_COURSESITEM', res.data);
      });
    },
    updateAttachmentsCoursesItem(context, { id, data }) {
      return axios.put(`${api}${router.app._route.params.coursesId}/attachments/${id}`, data).then((res) => {
        context.commit('GET_COURSESITEM', res.data);
      });
    },
    deleteAttachmentsCoursesItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.coursesId}/attachments/${payload}`).then(() => {
        context.dispatch('getCoursesItem');
      });
    },
    addLinksCoursesItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.coursesId}/links/`, payload).then((res) => {
        context.commit('GET_COURSESITEM', res.data);
      });
    },
    deleteLinksCoursesItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.coursesId}/links/${payload}`).then(() => {
        context.dispatch('getCoursesItem');
      });
    },
    addLessonsCoursesItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.coursesId}/lessons/`, payload).then((res) => {
        context.commit('GET_COURSESITEM', res.data);
      });
    },
    updateLessonsCoursesItem(context, { id, data }) {
      return axios.patch(`${api}${router.app._route.params.coursesId}/lessons/${id}`, data).then((res) => {
        context.commit('GET_COURSESITEM', res.data);
      });
    },
    deleteLessonsCoursesItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.coursesId}/lessons/${payload}`).then(() => {
        context.dispatch('getCoursesItem');
      });
    },
    addOptionsCoursesItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.coursesId}/options/`, payload).then((res) => {
        context.commit('GET_COURSESITEM', res.data);
      });
    },
    updateOptionsCoursesItem(context, { id, data }) {
      return axios.patch(`${api}${router.app._route.params.coursesId}/options/${id}`, data).then((res) => {
        context.commit('GET_COURSESITEM', res.data);
      });
    },
    deleteOptionsCoursesItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.coursesId}/options/${payload}`).then(() => {
        context.dispatch('getCoursesItem');
      });
    },
  },
  mutations: {
    GET_COURSELIST: (state, payload) => {
      state.courseList = payload;
      return state.courseList;
    },
    GET_COURSESLIST: (state, payload) => {
      state.coursesList = payload;
      return state.coursesList;
    },
    GET_COURSESITEM: (state, payload) => {
      state.coursesItem = payload;
      return state.coursesItem;
    },
  },
  getters: {
    courseList(state) {
      return state.courseList;
    },
    coursesList(state) {
      return state.coursesList;
    },
    coursesItem(state) {
      return state.coursesItem;
    },
  },
};
