<template>
  <div>
    <v-navigation-drawer color="primary" fixed overlay-opacity temporary v-model="drawer" width="220" class="mobile-menu">
      <div class="logo">
        <v-img max-height="34" max-width="108" contain src="@/assets/img/logo.svg" alt="logo"></v-img>
      </div>
      <MenuList />
    </v-navigation-drawer>
    <v-app-bar elevation="0" height="60" color="white" class="header">
      <div class="wrap d-flex flex-wrap align-center">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="d-lg-none">
          <v-icon v-if="!drawer">mdi-menu</v-icon>
          <v-icon v-else>mdi-close</v-icon>
        </v-app-bar-nav-icon>
        <div class="ms-lg-0 ms-5 h7 mt-1" v-if="$route.name == 'Course'">
          <router-link class="gray--text" to="/">Courses/</router-link><span>{{ courseTitle }}</span>
        </div>
        <div class="ms-lg-0 ms-5 h7 mt-1" v-if="$route.name == 'Course edit'">
          <router-link class="gray--text" to="/">Courses/</router-link>
          <router-link class="gray--text" :to="`/course/${$route.params.coursesId}`">{{ courseTitle }}/</router-link>
          <span>Edit</span>
        </div>
        <div class="ms-lg-0 ms-5 h7 mt-1" v-if="$route.path == '/calendar-edit'">
          <router-link class="gray--text" to="/calendar">Calendar/</router-link><span>{{ $route.name }}</span>
        </div>
        <div class="ms-lg-0 ms-5 h7 mt-1" v-if="$route.name == 'My student'">
          <router-link class="gray--text" to="/students">Students/</router-link>
          <span>{{ student.first_name }} {{ student.last_name }}</span>
        </div>
        <!-- <v-img max-height="38" max-width="108" contain src="@/assets/img/logo.svg" alt="logo"></v-img> -->
        <v-spacer></v-spacer>
        <v-menu offset-y nudge-left="340" nudge-bottom="8">
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              color="error"
              :content="profile.unseen_notifications"
              :value="!!profile.unseen_notifications"
              bottom
              offset-x="38"
              offset-y="24"
            >
              <v-btn @click="getNotifi" dark class="me-lg-3" min-width="44" height="44" text v-bind="attrs" v-on="on">
                <v-img max-width="20" height="20" contain src="@/assets/icon/bell.svg"></v-img>
              </v-btn>
            </v-badge>
          </template>
          <v-card class="py-3 view-without-scroll" max-width="400" width="100%" max-height="450">
            <v-card flat tile max-width="400" width="400" class="d-flex justify-space-between align-center flex-wrap px-4">
              <div class="h4">Notifications</div>
              <v-btn v-if="notifiList.results.find((item) => item.seen == false)" @click="readAllNotifi" text>Read all</v-btn>
            </v-card>
            <v-card tile flat v-if="!notifiList.results.length" class="d-flex align-center justify-center" height="350">
              <div>
                <v-img height="100" contain src="@/assets/img/epmty-notifi.svg"></v-img>
                <div class="h4 mt-7">There are no new notifications</div>
              </div>
            </v-card>
            <div v-for="i in notifiList.results" :key="i.id" class="link px-4" @click="notifi(i)">
              <div v-if="i.type == 'lesson_start_soon_v1'">
                <div class="d-flex align-center mb-1">
                  <div class="h10 text--text"><span v-if="!i.seen" class="url--text h5">•</span>Your lesson will start in 10 minutes</div>
                  <v-spacer></v-spacer>
                  <div class="h11 text--text">{{ new Date(i.created_at).toLocaleString() }}</div>
                </div>
                <div class="h10">{{ i.data.course.title }}: {{ i.data.lesson_title }}. Student: {{ i.data.student.full_name }}</div>
              </div>
              <div v-if="i.type == 'lesson_date_time_changed_v1'">
                <div class="d-flex align-center mb-1">
                  <div class="h10 text--text"><span v-if="!i.seen" class="url--text h5">•</span>Lesson time changed</div>
                  <v-spacer></v-spacer>
                  <div class="h11 text--text">{{ new Date(i.created_at).toLocaleString() }}</div>
                </div>
                <div class="h10">
                  {{ i.data.course.title }}: {{ i.data.lesson_title }} time changed to {{ new Date(i.data.begin_at).toLocaleString() }}.
                  Student: {{ i.data.student.full_name }}
                </div>
              </div>
              <div v-if="i.type == 'course_purchased_v1'">
                <div class="d-flex align-center mb-1">
                  <div class="h10 text--text"><span v-if="!i.seen" class="url--text h5">•</span>New course booking</div>
                  <v-spacer></v-spacer>
                  <div class="h11 text--text">{{ new Date(i.created_at).toLocaleString() }}</div>
                </div>
                <div class="h10">
                  {{ i.data.course.title }} has booked by {{ i.data.student.full_name }}. Purchased option: {{ i.data.option.title }}.
                  Lessons: {{ i.data.option.lessons }}
                </div>
              </div>
              <div v-if="i.type == 'course_new_review_v1'">
                <div class="d-flex align-center mb-1">
                  <div class="h10 text--text"><span v-if="!i.seen" class="url--text h5">•</span>New course review</div>
                  <v-spacer></v-spacer>
                  <div class="h11 text--text">{{ new Date(i.created_at).toLocaleString() }}</div>
                </div>
                <div class="h10">{{ i.data.student.full_name }} has submitted review to {{ i.data.course.title }} course.</div>
              </div>
              <v-divider class="hover mt-2 mb-1"></v-divider>
            </div>
          </v-card>
        </v-menu>
        <div class="text-center ms-5">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-list-item-avatar size="30" color="accent">
                  <v-img v-if="profile.picture != null" :src="profile.picture"></v-img>
                  <div v-else>
                    <v-icon>mdi-account mdi-24px</v-icon>
                  </div>
                </v-list-item-avatar>
              </div>
            </template>
            <v-list>
              <v-list-item link to="/profile">
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="logout">
                <v-list-item-icon>
                  <v-icon>mdi-download mdi-rotate-270</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Sing out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import MenuList from './MenuList.vue';

export default {
  components: {
    MenuList,
  },
  data() {
    return {
      drawer: false,
      page: 0,
    };
  },
  mounted() {
    this.$store.dispatch('getProfile');
    this.getNotifi();
    this.$store.dispatch('webSocket');
    this.$store.dispatch('timer');
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout');
    },
    notifi(i) {
      if (i.type == 'lesson_start_soon_v1') {
        this.$router.push(`/students/${i.data.student.id}`);
      } else if (i.type == 'lesson_date_time_changed_v1') {
        this.$router.push(`/students/${i.data.student.id}`);
      } else if (i.type == 'course_purchased_v1') {
        this.$router.push(`/course/${i.data.course.id}`);
      } else {
        this.$router.push(`/course/${i.data.course.id}`);
      }
      if (!i.seen) {
        this.$store.dispatch('reedNotification', i.id);
      }
    },
    getNotifi() {
      this.$store.dispatch('getNotificationsModal', this.page);
    },
    readAllNotifi() {
      this.$store.dispatch('reedAllNotificationsList');
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    courseTitle() {
      return this.$store.getters.coursesItem.title;
    },
    student() {
      return this.$store.getters.studentItem;
    },
    notifiList() {
      return this.$store.getters.notificationsModal;
    },
  },
  destroyed() {
    this.$store.dispatch('closeWebSocket');
    this.$store.dispatch('setProfile', {});
  },
};
</script>

<style>
.header .v-toolbar__content {
  width: calc(100% - 220px);
  margin-left: auto;
  border-bottom: 1px solid #eef2f2;
}
.header .v-badge__badge {
  padding: 3px 0;
  height: 11px;
  min-width: 11px;
  font-size: 6px;
}
.mobile-menu {
  top: 60px !important;
  height: calc(100% - 60px) !important;
}
@media screen and (max-width: 1263px) {
  .header .v-toolbar__content {
    width: initial;
    margin-left: initial;
  }
}
</style>
