import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: true,
      light: {
        primary: '#2c2c4e',
        secondary: '#6b6fe0',
        accent: '#f7f7fa',
        hover: '#eef2f2',
        text: '#595959',
        blue: '#5f9edb',
        url: '#0000ff',
        success: '#46c64e',
        warning: '#fe934a',
        gold: '#eacb5f',
        error: '#fc5d59',
        gray: '#b7bfcc',
        black: '#121212',
      },
    },
  },
});
