import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import auth from './modules/auth';
import profile from './modules/profile';
import courses from './modules/courses';
import windows from './modules/windows';
import messages from './modules/messages';
import students from './modules/students';
import wallet from './modules/wallet';
import reviews from './modules/reviews';
import notification from './modules/notification';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    auth,
    profile,
    courses,
    windows,
    messages,
    students,
    wallet,
    reviews,
    notification,
  },
});
