<template>
  <v-list flat color="primary">
    <v-list-item-group>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="`/${item.link}`"
        class="font-weight-medium ml-4 mr-6"
        active-class="active"
      >
        <v-list-item-icon class="my-4 mr-3">
          <v-badge :value="item.value" color="error" dot>
            <v-img
              height="16"
              max-width="16"
              :src="getImage(item.icon)"
              class="icon"
              :class="item.link == 'notifications' ? 'filter-white' : ''"
            ></v-img>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-content class="py-2">
          <v-list-item-title class="f18 white--text">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { value: '', title: 'Courses', link: '', icon: 'lesson' },
        { value: '', title: 'Schedule', link: 'schedule', icon: 'calendar' },
        { value: '', title: 'Messages', link: 'messages', icon: 'msg' },
        { value: '', title: 'Students', link: 'students', icon: 'lesson' },
        { value: '', title: 'Wallet', link: 'wallet', icon: 'msg' },
        { value: '', title: 'Reviews', link: 'reviews', icon: 'lesson' },
        { value: '', title: 'Notifications', link: 'notifications', icon: 'bell' },
      ],
    };
  },
  methods: {
    getImage(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 16px;
}
</style>
