<template>
  <div class="main-layout" :class="$route.meta.white ? 'white' : 'accent'">
    <Header />
    <div class="d-flex">
      <Menu class="d-none d-lg-block" />
      <Notification />
      <v-card class="ms-lg-auto mx-lg-0 mx-auto main-card" :class="$route.meta.white ? 'white' : 'accent'" tile flat>
        <router-view />
      </v-card>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Header from '@/components/Header';
import Menu from '@/components/Menu';
// import Footer from '@/components/Footer';
import Notification from '@/components/Notification';

export default {
  components: {
    // Footer,
    Header,
    Menu,
    Notification,
  },
};
</script>

<style scoped>
.main-layout {
  height: 100%;
}
.main-card {
  width: calc(100% - 220px);
}
@media screen and (max-width: 1263px) {
  .main-card {
    width: 100%;
  }
}
</style>
