import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VieMeta from 'vue-meta';
import titlePlugin from '@/plugins/title.plugin';
import countFilter from '@/filters/count.filter';

Vue.config.productionTip = false;

Vue.use(VieMeta);
Vue.use(titlePlugin);
Vue.filter('count', countFilter);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
