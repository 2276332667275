import '@/plugins/axios';
import router from '@/router/index';

const api = '/api/v1/';

const url = window.location.host;
let socket = null;
let timerID = null;

export default {
  state: {
    categoriesList: [],
    text: '',
    color: 'error',
  },
  actions: {
    getCategories(context) {
      return axios.get(`${api}categories/`).then((res) => {
        context.commit('GET_CATEGORIES', res.data);
      });
    },
    setCategories: (context, payload) => {
      context.commit('GET_CATEGORIES', payload);
    },
    webSocket(context) {
      socket = new WebSocket(`wss://${url}/tutor/ws/v1/?access_token=${sessionStorage.getItem('jwt_token')}`);
      socket.onopen = (e) => {};
      socket.onclose = () => {
        if (router.app._route.name != 'Login') {
          context.dispatch('check');
        }
      };
      socket.onmessage = (e) => {
        if (JSON.parse(e.data).event == 'conversation_update') {
          if (router.app._route.path == '/messages') {
            const data = new Object();
            data.search = router.app._route.query.src || '';
            data.page = router.app._route.query.page || 1;
            context.dispatch('getMsgList', data);
          }
        } else if (JSON.parse(e.data).event == 'message_update') {
          if (router.app._route.path == '/messages') {
            if (JSON.parse(e.data).payload.id == router.app._route.query.dialog) {
              context.getters.msgItemMsg.push(JSON.parse(e.data).payload);
            }
          }
        }
      };
      socket.onerror = () => {
        setTimeout(() => {
          if (router.app._route.name != 'Login') {
            context.dispatch('check');
          }
        }, 100);
      };
    },
    check(context) {
      if (!socket || socket.readyState == 3) {
        context.dispatch('webSocket');
      }
    },
    timer(context) {
      timerID = setInterval(() => {
        context.dispatch('check');
      }, 5000);
    },
    closeWebSocket() {
      clearInterval(timerID);
      if (socket) {
        socket.close(1000);
      }
      socket = null;
    },
    sendWs(context, payload) {
      if (payload) {
        socket.send(
          JSON.stringify({
            event: 'conversation_update',
            payload: {
              id: payload.conversation,
              last_message_seen_at: payload.updated_at,
            },
          })
        );
      }
    },
    setMsg: (context, payload) => {
      context.commit('GET_MSG', payload);
    },
    setColor: (context, payload) => {
      context.commit('GET_COLOR', payload);
    },
  },
  mutations: {
    GET_CATEGORIES: (state, payload) => {
      state.categoriesList = payload;
      return state.categoriesList;
    },
    GET_MSG: (state, payload) => {
      state.text = payload;
      return state.text;
    },
    GET_COLOR: (state, payload) => {
      state.color = payload;
      return state.color;
    },
  },
  getters: {
    categoriesList(state) {
      return state.categoriesList;
    },
    text(state) {
      return state.text;
    },
    color(state) {
      return state.color;
    },
  },
};
