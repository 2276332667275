import '@/plugins/axios';
import router from '@/router/index';

const api = '/tutor/api/v1/wallet';

export default {
  state: {
    walletAmount: {},
    walletList: {},
  },
  actions: {
    getWalletAmount(context) {
      return axios.get(`${api}`).then((res) => {
        context.commit('GET_WALLETAMOUNT', res.data);
      });
    },
    setWalletAmount: (context, payload) => {
      context.commit('GET_WALLETAMOUNT', payload);
    },
    getWalletList(context, payload) {
      if (`${router.app._route.path}?page=${payload}` != router.app._route.fullPath) {
        router.push(`${router.app._route.path}?page=${payload}`);
      }
      return axios.get(`${api}/transactions/?page=${payload - 1 || 0}`).then((res) => {
        context.commit('GET_WALLETLIST', res.data);
      });
    },
    setWalletList: (context, payload) => {
      context.commit('GET_WALLETLIST', payload);
    },
    postWalletAmount(context, payload) {
      return axios.post(`${api}/withdrawal`, payload).then(() => {
        context.dispatch('getWalletAmount');
        context.dispatch('getWalletList', router.app._route.query.page);
      });
    },
  },
  mutations: {
    GET_WALLETAMOUNT: (state, payload) => {
      state.walletAmount = payload;
      return state.walletAmount;
    },
    GET_WALLETLIST: (state, payload) => {
      state.walletList = payload;
      return state.walletList;
    },
  },
  getters: {
    walletAmount(state) {
      return state.walletAmount;
    },
    walletList(state) {
      return state.walletList;
    },
  },
};
