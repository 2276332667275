import '@/plugins/axios';

const api = '/tutor/api/v1/notifications/';

export default {
  state: {
    notificationsModal: { results: [] },
    notificationsList: { results: [] },
  },
  actions: {
    getNotificationsModal(context, payload) {
      return axios.get(`${api}?unseen_only=true&page=${payload}`).then((res) => {
        context.commit('GET_NOTIFILISTMODAL', res.data);
      });
    },
    getNotificationsList(context, payload) {
      return axios.get(`${api}?page=${payload}`).then((res) => {
        context.commit('GET_NOTIFILIST', res.data);
      });
    },
    reedAllNotificationsList(context) {
      return axios.post(`${api}seen-all`).then(() => {
        context.dispatch('getNotificationsListModal', 0);
      });
    },
    reedNotification(context, payload) {
      return axios.post(`${api}${payload}/seen`).then(() => {
        context.dispatch('getNotificationsListModal', 0);
      });
    },
  },
  mutations: {
    GET_NOTIFILISTMODAL: (state, payload) => {
      state.notificationsModal = payload;
      return state.notificationsModal;
    },
    GET_NOTIFILIST: (state, payload) => {
      state.notificationsList = payload;
      return state.notificationsList;
    },
  },
  getters: {
    notificationsModal(state) {
      return state.notificationsModal;
    },
    notificationsList(state) {
      return state.notificationsList;
    },
  },
};
