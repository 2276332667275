import '@/plugins/axios';
import router from '@/router/index';

const api = '/tutor/api/v1/students/';

export default {
  state: {
    studentList: [{ id: 'all', first_name: 'All', last_name: '' }],
    studentsList: {},
    studentItem: {},
    studentSchedule: [],
    studentCourses: [],
  },
  actions: {
    getStudentList(context) {
      return axios.get(`${api}all/`).then((res) => {
        context.commit('GET_STUDENTLIST', res.data);
      });
    },
    setStudentList: (context, payload) => {
      context.commit('GET_STUDENTLIST', payload);
    },
    getStudentsList(context, { search, page }) {
      if (`${router.app._route.path}?${search ? `src=${search}&` : ''}page=${page}` != router.app._route.fullPath) {
        window.history.pushState({}, '', `${router.app._route.path}?${search ? `src=${search}&` : ''}page=${page}`);
      }
      return axios.get(`${api}?${search ? `src=${search}&` : ''}page=${page - 1 || 0}`).then((res) => {
        context.commit('GET_STUDENTSLIST', res.data);
      });
    },
    setStudentsList: (context, payload) => {
      context.commit('GET_STUDENTSLIST', payload);
    },
    getStudentItem(context) {
      return axios.get(`${api}${router.app._route.params.studentId}`).then((res) => {
        context.commit('GET_STUDENTITEM', res.data);
      });
    },
    setStudentItem: (context, payload) => {
      context.commit('GET_STUDENTITEM', payload);
    },
    getStudentSchedule(context) {
      return axios.get(`${api}${router.app._route.params.studentId}/schedule/`).then((res) => {
        context.commit('GET_STUDENTSCHEDULE', res.data);
      });
    },
    setStudentSchedule: (context, payload) => {
      context.commit('GET_STUDENTSCHEDULE', payload);
    },
    getStudentCourses(context) {
      return axios.get(`${api}${router.app._route.params.studentId}/courses/progress/`).then((res) => {
        context.commit('GET_STUDENTCOURSES', res.data);
      });
    },
    setStudentCourses: (context, payload) => {
      context.commit('GET_STUDENTCOURSES', payload);
    },
    editStudentLesson(context, { id, data }) {
      return axios.post(`/tutor/api/v1/booking-lessons/${id}/postpone`, data);
    },
  },
  mutations: {
    GET_STUDENTLIST: (state, payload) => {
      state.studentList = payload;
      return state.studentList;
    },
    GET_STUDENTSLIST: (state, payload) => {
      state.studentsList = payload;
      return state.studentsList;
    },
    GET_STUDENTITEM: (state, payload) => {
      state.studentItem = payload;
      return state.studentItem;
    },
    GET_STUDENTSCHEDULE: (state, payload) => {
      state.studentSchedule = payload;
      return state.studentSchedule;
    },
    GET_STUDENTCOURSES: (state, payload) => {
      state.studentCourses = payload;
      return state.studentCourses;
    },
  },
  getters: {
    studentList(state) {
      return state.studentList;
    },
    studentsList(state) {
      return state.studentsList;
    },
    studentItem(state) {
      return state.studentItem;
    },
    studentSchedule(state) {
      return state.studentSchedule;
    },
    studentCourses(state) {
      return state.studentCourses;
    },
  },
};
