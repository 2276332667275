import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Courses',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/course-edit/:coursesId',
      name: 'Course edit',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/CourseEdit.vue'),
    },
    {
      path: '/course/:coursesId',
      name: 'Course',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Course.vue'),
    },
    {
      path: '/new-course',
      name: 'Create new course',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/NewCourse.vue'),
    },
    {
      path: '/schedule',
      name: 'Schedule review',
      meta: {
        layout: 'Main',
        auth: true,
        white: true,
      },
      component: () => import('@/views/ScheduleList.vue'),
    },
    {
      path: '/schedule-edit',
      name: 'Edit time slots',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/ScheduleEdit.vue'),
    },
    {
      path: '/messages',
      name: 'Messages',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Messages.vue'),
    },
    {
      path: '/students',
      name: 'My students',
      meta: {
        layout: 'Main',
        auth: true,
        white: true,
      },
      component: () => import('@/views/StudentsList.vue'),
    },
    {
      path: '/students/:studentId',
      name: 'My student',
      meta: {
        layout: 'Main',
        auth: true,
        white: true,
      },
      component: () => import('@/views/Student.vue'),
    },
    {
      path: '/wallet',
      name: 'Wallet',
      meta: {
        layout: 'Main',
        auth: true,
        white: true,
      },
      component: () => import('@/views/WalletList.vue'),
    },
    {
      path: '/reviews',
      name: 'Reviews',
      meta: {
        layout: 'Main',
        auth: true,
        white: true,
      },
      component: () => import('@/views/ReviewsList.vue'),
    },
    {
      path: '/notifications',
      name: 'Notifications',
      meta: {
        layout: 'Main',
        auth: true,
        white: true,
      },
      component: () => import('@/views/Notifications.vue'),
    },
    {
      path: '/profile',
      name: 'Profile',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Profile.vue'),
    },
    {
      path: '/sign-in',
      name: 'Login',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '/sign-up',
      name: 'Sign-up',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Registration.vue'),
    },
    {
      path: '/forgot-password',
      name: 'Forgot password page',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/ForgotPassword.vue'),
    },
    {
      path: '/password-reset',
      name: 'Create new password',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/NewPassword.vue'),
    },
    {
      path: '/email-confirmation',
      name: 'Email confirmation',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Confirm.vue'),
    },
    {
      path: '/expired',
      name: 'Expired',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Expired.vue'),
    },
    {
      path: '*',
      redirect: { name: 'Courses' },
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const currentUser = sessionStorage.getItem('jwt_token');
  const requireAuth = to.matched.some((record) => record.meta.auth);

  if (requireAuth && !currentUser) {
    next({ name: 'Login', query: { from: to.fullPath } });
  } else {
    next();
  }
});

export default router;
