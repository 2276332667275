<template>
  <div class="empty-header">
    <v-app-bar height="68" color="white" elevation="0">
      <div class="wrap-header d-flex flex-wrap align-center">
        <v-img max-height="34" max-width="66" contain src="@/assets/img/logoB.svg" alt="logo"></v-img>
        <v-spacer></v-spacer>
        <div class="h8 mr-5" v-if="$route.name == 'Login'">
          <span class="text--text pr-3">Do not have an account?</span>
          <router-link class="blue--text" to="/sign-up">Registration</router-link>
        </div>
        <div class="h8 mr-5" v-if="$route.name == 'Sign-up'">
          <span class="text--text pr-3">Already a member?</span>
          <router-link class="blue--text" to="/sign-in">Sign In</router-link>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn plain value="help" v-bind="attrs" v-on="on">{{ select.name }} <v-icon>mdi-chevron-down</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item @click="select = item" v-for="item in lang" :key="item.key">{{ item.name }}</v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-divider></v-divider>
    <div class="empty-layout">
      <v-fab-transition>
        <router-view />
      </v-fab-transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      select: { name: 'ENG', key: 'en' },
      lang: [
        { name: 'AR', key: 'ar' },
        { name: 'ENG', key: 'en' },
      ],
    };
  },
};
</script>

<style>
.empty-layout {
  width: 100%;
  min-height: calc(100vh - 69px);
  background: #ffffff;
  /* background: url(../assets/img/empty.jpg);
  background-position: 50% 90%;
  background-size: cover; */
}
.wrap-header {
  max-width: 1152px;
  width: 100%;
  margin: 0 auto;
}
</style>
