<template>
  <v-card flat tile width="220" min-height="100vh" color="primary" class="card">
    <v-card color="primary" tile flat to="/" class="logo">
      <v-img max-height="34" contain src="@/assets/img/logo.svg" alt="logo"></v-img>
    </v-card>
    <MenuList />
  </v-card>
</template>

<script>
import MenuList from './MenuList.vue';

export default {
  components: {
    MenuList,
  },
};
</script>

<style scoped>
.card {
  margin-top: -60px;
  position: fixed;
  height: calc(100vh - 60px);
  overflow: auto;
  z-index: 1;
}
</style>
