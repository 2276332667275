import '@/plugins/axios';
import router from '@/router/index';

const api = '/tutor/api/v1/';

export default {
  actions: {
    logout(context) {
      return axios.post(`${api}sign-out`).then(() => {
        sessionStorage.clear();
        router.push('/sign-in');
      });
    },
    signIn(context, payload) {
      return axios.post(`${api}sign-in/email`, payload);
    },
    social(context, payload) {
      return axios.post(`${api}sign-in/social`, payload);
    },
    signUp(context, payload) {
      return axios.post(`${api}sign-up/email`, payload);
    },
    requestPassword(context, payload) {
      return axios.post(`${api}request-password-reset`, payload);
    },
    resetPassword(context, payload) {
      return axios.post(`${api}password-reset`, payload);
    },
    confirmEmail(context, payload) {
      return axios.post(`${api}email-confirmation`, payload);
    },
  },
};
