<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout';
import MainLayout from '@/layouts/MainLayout';

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout';
    },
  },
  components: {
    EmptyLayout,
    MainLayout,
  },
};
</script>

<style lang="scss">
#app * {
  font-family: 'Inter', sans-serif;
}
body,
div,
#app {
  color: var(--v-black-base);
}
* {
  scrollbar-color: #595959 #f7f7fa;
  scrollbar-width: thin;
}
*::-webkit-scrollbar {
  width: 6px;
  background-color: var(--v-accent-base);
}
*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--v-text-base);
}
*::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: var(--v-accent-base);
}
a {
  text-decoration: none;
}
.h1 {
  font-weight: bold;
  font-size: 26px;
  line-height: 150%;
}
.h2 {
  font-weight: bold;
  font-size: 22px;
  line-height: 150%;
}
.h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
}
.h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}
.h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}
.h7 {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}
.h8 {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
}
.v-btn__content,
.h9 {
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
}
.h10 {
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
}
.h11 {
  font-weight: normal;
  font-size: 11px;
  line-height: 140%;
}
.h12 {
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
}
.h13 {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 140% !important;
}
.h14 {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
}
.h15 {
  font-weight: normal;
  font-size: 8px;
  line-height: 140%;
}
.wrap {
  max-width: 932px;
  width: 100%;
  margin: 0 auto;
}
.width100 {
  width: 100%;
}
#app .v-date-picker-table {
  height: min-content !important;
}
.height100 {
  height: 100%;
}
.link {
  cursor: pointer;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
}
.v-btn__content {
  text-transform: none;
  opacity: 1 !important;
}
.v-input__icon--clear .v-icon {
  font-size: 16px;
}
.v-text-field__details {
  padding: 0 !important;
}
#app .v-text-field--outlined.v-input--is-focused fieldset {
  border-color: var(--v-secondary-base);
}
#app textarea::placeholder,
#app input::placeholder,
#app .v-text-field--outlined fieldset,
.v-input__append-inner .v-icon,
.v-input__prepend-inner .v-icon {
  color: var(--v-gray-base);
  border-color: var(--v-gray-base);
}
.v-messages__message {
  color: var(--v-error-base);
}
#app .v-text-field--outlined.error--text fieldset {
  border-color: var(--v-error-base) !important;
}
.v-textarea .v-counter {
  font-size: 9px !important;
  color: var(--v-text-base) !important;
}
.field44 .v-input__append-inner,
.field44 .v-input__prepend-inner {
  margin-top: 10px !important;
}
.field44 .v-text-field__slot {
  margin-top: 2px !important;
}
.v-text-field--solo .v-input__slot {
  box-shadow: initial !important;
}
#app .phone.v-text-field {
  max-width: 140px;
}
#app .phone.v-text-field input {
  min-width: 1px;
}
#app .v-card {
  box-shadow: -2px 3px 6px rgba(122, 122, 122, 0.04);
}
.filter-gray {
  filter: contrast(0%) brightness(160%);
}
.filter-white {
  filter: invert(100%) hue-rotate(180deg) brightness(101%) contrast(118%);
}
.filter-black {
  filter: invert(100%);
}
.clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clip2 {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 31px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
thead tr th {
  height: 44px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--v-text-base) !important;
}
tbody tr td {
  height: 46px !important;
  font-weight: 500 !important;
}
</style>
