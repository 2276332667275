import '@/plugins/axios';
import router from '@/router/index';

const api = '/tutor/api/v1/reviews/';

export default {
  state: {
    rewiewsList: {},
  },
  actions: {
    getRewiewsList(context, payload) {
      if (`${router.app._route.path}?page=${payload}` != router.app._route.fullPath) {
        router.push(`${router.app._route.path}?page=${payload}`);
      }
      return axios.get(`${api}?page=${payload - 1 || 0}`).then((res) => {
        context.commit('GET_REWIEWSLIST', res.data);
      });
    },
    setRewiewsList: (context, payload) => {
      context.commit('GET_REWIEWSLIST', payload);
    },
  },
  mutations: {
    GET_REWIEWSLIST: (state, payload) => {
      state.rewiewsList = payload;
      return state.rewiewsList;
    },
  },
  getters: {
    rewiewsList(state) {
      return state.rewiewsList;
    },
  },
};
