import '@/plugins/axios';

const api = '/tutor/api/v1/profile';

export default {
  state: {
    profile: {},
    profileEducation: [],
    profileEducationItem: {},
  },
  actions: {
    getProfile(context) {
      return axios.get(`${api}`).then((res) => {
        context.commit('GET_PROFILE', res.data);
      });
    },
    setProfile: (context, payload) => {
      context.commit('GET_PROFILE', payload);
    },
    updateProfile(context, payload) {
      return axios({
        method: 'patch',
        url: api,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_PROFILE', res.data);
      });
    },
    deletePhotoProfile(context) {
      return axios.delete(`${api}/picture`).then(() => {
        context.dispatch('getProfile');
      });
    },
    deleteMediaProfile(context) {
      return axios.delete(`${api}/media`).then(() => {
        context.dispatch('getProfile');
      });
    },
    confirmProfile(context) {
      return axios.post(`${api}/resend-email-confirmation`).then(() => {
        context.dispatch('getProfile');
      });
    },
    newPhoneProfile(context, payload) {
      return axios.post(`${api}/phone`, payload);
    },
    newPhoneOtpProfile(context, payload) {
      return axios.post(`${api}/phone/otp`, payload).then(() => {
        context.dispatch('getProfile');
      });
    },
    newPasswordProfile(context, payload) {
      return axios.post(`${api}/password`, payload);
    },
    getProfileEducation(context) {
      return axios.get(`${api}/education/`).then((res) => {
        context.commit('GET_PROFILEEDUCATION', res.data);
      });
    },
    setProfileEducation: (context, payload) => {
      context.commit('GET_PROFILEEDUCATION', payload);
    },
    addProfileEducation(context, payload) {
      return axios.post(`${api}/education/`, payload).then((res) => {
        context.dispatch('getProfileEducation');
      });
    },
    updateProfileEducationItem(context, { id, data }) {
      return axios.put(`${api}/education/${id}`, data).then((res) => {
        context.dispatch('getProfileEducation');
      });
    },
    deleteProfileEducationItem(context, payload) {
      return axios.delete(`${api}/education/${payload}`).then(() => {
        context.dispatch('getProfileEducation');
      });
    },
    addProfileEducationAttachments(context, { id, data }) {
      return axios({
        method: 'post',
        url: `${api}/education/${id}/attachments/`,
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        context.dispatch('getProfileEducation');
      });
    },
    updateProfileEducationAttachments(context, { id, attId, data }) {
      return axios.put(`${api}/education/${id}/attachments/${attId}`, data).then(() => {
        context.dispatch('getProfileEducation');
      });
    },
    deleteProfileEducationAttachments(context, { id, attId }) {
      return axios.delete(`${api}/education/${id}/attachments/${attId}`).then(() => {
        context.dispatch('getProfileEducation');
      });
    },
  },
  mutations: {
    GET_PROFILE: (state, payload) => {
      state.profile = payload;
      return state.profile;
    },
    GET_PROFILEEDUCATION: (state, payload) => {
      state.profileEducation = payload;
      return state.profileEducation;
    },
    GET_PROFILEEDUCATIONITEM: (state, payload) => {
      state.profileEducationItem = payload;
      return state.profileEducationItem;
    },
  },
  getters: {
    profile(state) {
      return state.profile;
    },
    profileEducation(state) {
      return state.profileEducation;
    },
    profileEducationItem(state) {
      return state.profileEducationItem;
    },
  },
};
